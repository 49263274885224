<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                          <div class="card-header actions-toolbar border-0">
                            <div class="row justify-content-between align-items-center">
                                <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                    <h2 class="d-inline-block mb-0 card-title">參數設定</h2>
                                </div>
                            </div>
                          </div>
                          <br/>
                            <!-- ==========主要內容區========== -->
                            <v-form ref="form" class="px-12" v-model="isValid">
                              <v-row>
                                <v-subheader>
                                  參數設定
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>   
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    label="參數名稱" 
                                    outlined v-model="paraData.name" 
                                    persistent-hint
                                    :rules="[rules.required]" required
                                    >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    label="參數代碼" 
                                    outlined v-model="paraData.code" 
                                    persistent-hint
                                    :rules="[rules.required]" required
                                    >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    label="說明" 
                                    outlined v-model="paraData.description" 
                                    persistent-hint
                                    >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="submitData" :disabled="!isValid">
                                      儲存
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" color="#4D4D4D" large @click="toCancel">
                                      回上一頁
                                    </v-btn>
                              </v-row>
                            </v-form>
                            <!-- ==========主要內容區========== -->
                            <br/>
                        </div>
                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading" style="z-index:9999"></loadingMask>

    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {saveSysParam, addSysParam} from "@/api/hyirecoApi";

export default {
  name: 'paraEdit',
  mixins: [commonMixin],
  created(){
    this.authValidate();

    this.auth = {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
      }
    };

    if (this.$route.params.paraData !== "init") { 
        this.paraData = JSON.parse(this.$route.params.paraData);
    }
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted(){
    this.userName = this.loginAuth.memberName;
  },
  methods: {
    submitData: function() {
        var vm = this;
        vm.isLoading = true;

        var apiObj = saveSysParam;
          if (this.$route.params.paraData === "init") { 
            apiObj = addSysParam;
          }

        if (vm.$route.params.mainParamId === "root") {
            vm.paraData.mainParamId = "";
        } else {
            vm.paraData.mainParamId = vm.$route.params.mainParamId;
        }
        
        var dataObj = new Object();
        dataObj.sysParam = vm.paraData;

        console.log("dataObj = " + JSON.stringify(dataObj));
        apiObj(dataObj, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                vm.$swal(responseData.message);
                if (responseData.statusCode === "1") {
                vm.toCancel();
                }
                //console.log("responseData = " + JSON.stringify(response));
                vm.isLoading = false;
            }
        });
    },
    toCancel: function () {
        this.$router.push({ path: "/parameter/paraList/" + this.$route.params.mainParamId });
      // if (this.loginAuth.role === "S") {
      //   this.$router.push({ path: "/member/jsMemberList" });
      // } else {
      //   this.$router.push({ path: "/index" });
      // }
    },
  },
  watch: {
  },
  data: () => ({
    rules: {
      required: value => !!value || '必填欄位',
      requiredForLabor: value => !!value || '若沒有請填 無',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || '不符規格的 e-mail 信箱'
      },
    },
    paraData: {},
    userName: "",
    isLoading: false,
    auth:{},
    isValid: true,
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>
.v-input__slot > .v-label
{
    margin-bottom: 0px;
}
.v-subheader {
    color: rgb(34, 34, 34) !important;
    font-size: large;
}
</style>